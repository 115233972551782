.Blog__topBlock {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Blog__topBlock::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.6), rgba(255, 255, 255, 0.1));
}

.Blog__topBlock .h2 {
    hyphens: auto;
}

.topBlock__textContainer {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    width: 70%;
}

.topBlock__textContainer span {
    color: var(--color-background);
}

.topBlock__textContainer a {
    color: var(--color-secondary);
    text-decoration: none;
}

.BlogPost {
    overflow-x: hidden;
    word-break: break-word;
}

.BlogPost img {
    margin: 1.5rem 0;
    max-width: 100%;
}

@media (min-width: 576px) {
    .topBlock__textContainer {
        width: 40%;
    }
}
