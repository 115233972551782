.StartseiteLeistungenBlock {
    position: relative;
    width: 100%;
    background-color: var(--color-primary-dark);
    padding-bottom: 3.5rem;
}

.StartseiteLeistungenBlock * {
    color: var(--color-background);
}

.StartseiteLeistungenBlock a {
    text-decoration: none;
}

.StartseiteLeistungenBlock__bottom {
    position: absolute;
    bottom: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.StartseiteLeistungenBlock__bottom i {
    font-size: 1.8rem;
}

.StartseiteLeistungenBlock__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border: 2px solid var(--color-secondary);
    border-radius: 4rem;
    margin-bottom: 1.5rem;
}

.StartseiteLeistungenBlock__icon img {
    width: 4.5rem;
    height: auto;
}

.StartseiteLeistungenBlock__icon--small img {
    width: auto;
    height: 4.5rem;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
}

.StartseiteLeistungenBlock__icon--small:hover img {
    transform: scale(1.05);
}

/* Hover Effekt */

.top__links .StartseiteLeistungenBlock__icon,
span {
    transition: all 0.3s ease;
}

.top__links:hover .StartseiteLeistungenBlock__icon {
    border: 3px solid var(--color-primary-dark);
}

.top__links:hover span {
    color: var(--color-secondary);
}

@media (min-width: 768px) {
    .StartseiteLeistungenBlock__icon {
        width: 11rem;
        height: 11rem;
        border: 3px solid var(--color-secondary);
        border-radius: 5.5rem;
    }

    .StartseiteLeistungenBlock__icon img {
        width: 6rem;
    }

    .StartseiteLeistungenBlock__icon--small {
        height: 5rem;
    }
}
