.ContentBlock1 {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
}

.ContentBlock1 * {
    color: var(--color-background);
}

.ContentBlock1__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.ContentBlock1__overlay.left {
    background-image: linear-gradient(90deg, rgba(25, 25, 25, 0.8), rgba(50, 50, 50, 0.7));
}

.ContentBlock1__overlay.right {
    background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.8), rgba(50, 50, 50, 0.7));
}

.ContentBlock1 .content {
    position: relative;
}

.ContentBlock1__box {
    position: absolute;
    top: 25vh;
    width: 32rem;
    max-width: 100%;
}

.box__usps {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.box__usps li {
    display: flex;
    color: var(--color-secondary);
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-style: italic;
}

.box__usps li::before {
    content: "\F26A";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
}

.ContentBlock1__bottom {
    position: absolute;
    bottom: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: upAndDown 1.3s infinite ease alternate;
    cursor: pointer;
}

.ContentBlock1__bottom i {
    font-size: 1.8rem;
}

@keyframes upAndDown {
    from {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    to {
        transform: translateX(-50%) translateY(1rem);
        opacity: 0.8;
    }
}

@media (min-width: 576px) {
    .ContentBlock1__overlay.left {
        background-image: linear-gradient(90deg, rgba(25, 25, 25, 0.8), rgba(255, 255, 255, 0.1));
    }

    .ContentBlock1__overlay.right {
        background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.8), rgba(255, 255, 255, 0.1));
    }
}
