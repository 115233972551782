/* roboto-100 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: local(""), url("./fonts/roboto-v30-latin-100.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-100italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    src: local(""), url("./fonts/roboto-v30-latin-100italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-100italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("./fonts/roboto-v30-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    src: local(""), url("./fonts/roboto-v30-latin-300italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/roboto-v30-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: local(""), url("./fonts/roboto-v30-latin-italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("./fonts/roboto-v30-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    src: local(""), url("./fonts/roboto-v30-latin-500italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("./fonts/roboto-v30-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: local(""), url("./fonts/roboto-v30-latin-700italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: local(""), url("./fonts/roboto-v30-latin-900.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    src: local(""), url("./fonts/roboto-v30-latin-900italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/roboto-v30-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
