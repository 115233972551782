.Blog__halfSizeThumb {
    position: relative;
    width: 100%;
    height: 30rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Blog__halfSizeThumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.6);
    transition: 0.3s ease;
}

.Blog__halfSizeThumb:hover {
    cursor: pointer;
}

.Blog__halfSizeThumb:hover::before {
    background: rgba(25, 25, 25, 0.5);
}

.row .col-6:nth-child(n + 3) .Blog__halfSizeThumb {
    animation: fadeIn 0.6s ease-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        height: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        height: 25rem;
    }
}

.halfSizeThumb__textContainer {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    width: 70%;
}

.halfSizeThumb__textContainer .h2 {
    hyphens: auto;
}

.halfSizeThumb__textContainer span {
    color: var(--color-background);
}

.halfSizeThumb__textContainer a {
    color: var(--color-secondary);
    text-decoration: none;
}

@media (min-wi) {
}
