.rezptSliderItem:last-of-type {
    margin-bottom: 0 !important;
}

.rezptSliderItem__titel {
    display: block;
    font-weight: 700;
    hyphens: auto;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rezptSliderItem__exzerpt {
    display: block;
    hyphens: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
