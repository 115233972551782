.tours {
    position: relative;
    width: 100%;
    height: 50rem;
}

.tours::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    opacity: 0.6;
    z-index: 1;
}

.tours::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/img/SUP_MapsPreview.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: 0;
}

.tours__dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

@media (min-width: 768px) {
    .tours {
        height: 100%;
    }
}

.ul--supTour {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ul--supTour li {
    margin-bottom: 1rem;
    display: flex;
}

.ul--supTour li::before {
    content: "\F3CB";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
}

.ul--supTour__liContainer {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.ul--supTour .flag--violet::before {
    color: violet;
}

.ul--supTour .flag--dark-violet::before {
    color: darkviolet;
}

.ul--supTour .flag--yellow::before {
    color: yellow;
}

.ul--supTour .flag--blue::before {
    color: cadetblue;
}

.ul--supTour .flag--green::before {
    color: green;
}

.ul--supTour .flag--red::before {
    color: red;
}

.ul--supTour .flag--orange::before {
    color: orange;
}

.ul--supTour .flag--black::before {
    color: black;
}
