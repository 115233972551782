.instafeed--placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.instafeed--placeholder__pb {
    background-image: linear-gradient(220deg, #db00c4, #ffc628);
    border-radius: 50%;
    padding: 3px;
    transition: all 0.3s ease;
}

.instafeed--placeholder__pb img {
    width: 6rem;
    height: auto;
    border-radius: 50%;
    border: 3px solid #fff;
    cursor: pointer;
}

#instafeed {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;
}

.instafeed-cont {
    width: calc(1400px / 4.2);
    display: flex;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.instafeed-cont:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.instafeed-item {
    width: 100%;
}

.instafeed-img {
    width: auto;
    height: calc(1400px / 4.2);
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.instafeed-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 2.3rem; /* fallback */
    max-height: calc(2.3rem * 2); /* fallback */
    margin-bottom: 2rem;
}
