.bg-primary-dark {
    background-color: var(--color-primary-dark);
}

.bg-background {
    background-color: var(--color-background);
}

.form-checkbox {
    height: 25px !important;
    width: 25px !important;
    min-width: 25px;
    border: solid 1px #707070;
}

.newsletter-content {
    max-width: 790px;
}
