.footer {
    width: 100%;
    background-color: var(--color-background-dark);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__karte {
    position: relative;
    width: 100%;
    height: 25rem;
}

.footer__karte::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    opacity: 0.6;
    z-index: 1;
}

.footer__karte::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/img/Footer_MapsPreview.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: 0;
}

.karte__dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.footer__sub {
    background-color: var(--color-primary-dark);
}

.footer__sub * {
    color: var(--color-background);
}

.footer__sub a {
    text-decoration: none;
}

.footer__sub img {
    height: 2.5rem;
}
