@import url("https://use.typekit.net/dtx2awo.css");

.ContentBlock2 {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-color: var(--color-primary-dark);
    margin-bottom: 3rem;
}

.ContentBlock2 * {
    color: var(--color-background);
}

.ContentBlock2__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border: 3px solid var(--color-secondary);
    border-radius: 4rem;
}

.ContentBlock2__icon img {
    width: 3.5rem;
    height: auto;
}

.ContentBlock2__zitat {
    position: relative;
    text-align: center;
    width: 48rem;
    max-width: 100%;
}

@media (min-width: 768px) {
    .ContentBlock2 .content {
        padding: 7rem 0;
    }

    .ContentBlock2__icon {
        width: 11rem;
        height: 11rem;
        border: 3px solid var(--color-secondary);
        border-radius: 5.5rem;
    }

    .ContentBlock2__icon img {
        width: 6rem;
    }

    .ContentBlock2__zitat::before {
        content: "\201E";
        font-family: rockwell, sans-serif;
        font-weight: 400;
        font-style: normal;
        position: absolute;
        left: -3rem;
        top: -7.5rem;
        font-size: 6.5rem;
        color: var(--color-secondary);
    }

    .ContentBlock2__zitat::after {
        content: "\201C";
        font-family: rockwell, sans-serif;
        font-weight: 400;
        font-style: normal;
        position: absolute;
        right: -3rem;
        bottom: -7.5rem;
        font-size: 6.5rem;
        color: var(--color-secondary);
    }
}
