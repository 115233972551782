.Blog__fullSizeThumb {
    position: relative;
    width: 100%;
    height: 45rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Blog__fullSizeThumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.8), rgba(50, 50, 50, 0.7));
}

.fullSizeThumb__textContainer {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    width: calc(100% - 6rem);
}

.fullSizeThumb__textContainer .h2 {
    hyphens: auto;
}

.fullSizeThumb__textContainer span {
    color: var(--color-background);
}

.fullSizeThumb__textContainer a {
    color: var(--color-secondary);
    text-decoration: none;
}

@media (min-width: 768px) {
    .Blog__fullSizeThumb::before {
        background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.6), rgba(255, 255, 255, 0.1));
    }

    .fullSizeThumb__textContainer {
        width: 40%;
    }
}
