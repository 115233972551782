.ContentBlockGallery {
    display: grid;
    grid-template-areas:
        "item2 item2"
        "item1 item4"
        "item3 item3";
    gap: 1rem;
    width: 100%;
}

.ContentBlockGallery div {
    height: calc((100vw / 2.5));
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ContentBlockGallery > div:nth-child(1) {
    grid-area: item1;
}

.ContentBlockGallery > div:nth-child(2) {
    grid-area: item2;
}

.ContentBlockGallery > div:nth-child(3) {
    grid-area: item3;
}

.ContentBlockGallery > div:nth-child(4) {
    grid-area: item4;
}

/* Media-Query ab 992px, weil var(--content-width) davor in % (Berechnung der Höhe nicht mgl.) */
@media (min-width: 992px) {
    .ContentBlockGallery {
        grid-template-areas:
            "item1 item2 item2"
            "item3 item3 item4";
        gap: 1.5rem;
    }

    .ContentBlockGallery div {
        height: calc((var(--content-width) / 3) - 1.5rem);
    }
}
