.StartseiteLandingBlock {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.StartseiteLandingBlock * {
    color: var(--color-background);
}

.StartseiteLandingBlock__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.StartseiteLandingBlock__overlay.left {
    background-image: linear-gradient(90deg, rgba(25, 25, 25, 0.8), rgba(50, 50, 50, 0.7));
}

.StartseiteLandingBlock__overlay.right {
    background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.8), rgba(50, 50, 50, 0.7));
}

.StartseiteLandingBlock .content {
    position: relative;
}

.StartseiteLandingBlock__box {
    position: absolute;
    top: 15rem;
    max-width: 36rem;
}

@media (min-width: 576px) {
    .StartseiteLandingBlock__box {
        top: 20rem;
    }

    .StartseiteLandingBlock__overlay.left {
        background-image: linear-gradient(90deg, rgba(25, 25, 25, 0.6), rgba(255, 255, 255, 0.1));
    }

    .StartseiteLandingBlock__overlay.right {
        background-image: linear-gradient(270deg, rgba(25, 25, 25, 0.6), rgba(255, 255, 255, 0.1));
    }
}

.fs-big-resp {
    font-size: clamp(30px, 4vw, 50px);
}
