.SocialIcons {
    display: flex;
    gap: 1.5rem;
    margin-top: auto;
    display: none;
}

.SocialIcons a span {
    display: none;
}

.SocialIcons a i {
    color: var(--color-background);
}

@media (min-width: 768px) {
    .SocialIcons {
        position: fixed;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        z-index: 9;
    }

    .SocialIcons a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        line-height: 1;
        text-decoration: none;
    }

    .SocialIcons a span {
        font-size: 0.9rem;
        color: var(--color-background);
        display: none;
    }

    .SocialIcons a i {
        font-size: 1.2rem;
        color: var(--color-background);
    }

    .SocialIcons a:hover span {
        opacity: 1;
        display: inline;
        animation: fade_in_scale 0.2s;
    }
}
