.ReviewsItem {
    width: 20rem;
    flex: 1 100%;
}

@media (min-width: 576px) {
    .ReviewsItem {
        flex: 1 33.33%;
    }
}

@media (min-width: 1400px) {
    .ReviewsItem {
        flex: 4;
    }
}

.ReviewsItem__topGrid {
    display: grid;
    grid-template-areas: "img name" "img rating";
    grid-template-columns: 3rem auto;
    column-gap: 1rem;
    align-items: center;
}

.topGrid__img {
    position: relative;
    height: 3rem;
    width: 3rem;
    grid-area: img;
    border-radius: 50%;
}

.topGrid__img img {
    position: absolute;
    z-index: 2;
}

.topGrid__img--placeholder {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: white;
    border: 1px solid white;
    text-transform: uppercase;
    font-size: 1.5rem;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.topGrid__name {
    grid-area: name;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.topGrid__rating {
    grid-area: rating;
}
.rating__star {
    color: var(--color-secondary);
}

.ReviewsItem__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ReviewsItem__keepReading {
    font-size: 1rem;
    color: rgb(150, 150, 150);
    text-decoration: none;
}

.ReviewsItem__keepReading:hover {
    font-size: 1rem;
    color: rgb(150, 150, 150);
    text-decoration: none;
    cursor: pointer;
}
