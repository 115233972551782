.ContentBlockUSPs {
    position: relative;
    background-color: var(--color-background-dark);
    margin: 4rem 0;
    overflow-x: hidden;
}

.ContentBlockUSPs__left .h4 {
    color: var(--color-primary);
}

.left__usps {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.left__usps li {
    display: flex;
    color: var(--color-primary);
    margin-bottom: 1rem;
    font-style: italic;
}

.left__usps li::before {
    content: "\F26A";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
}

.ContentBlockUSPs__right {
    z-index: 1;
}

.right__cta {
    font-family: "rollerscript-smooth", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    color: var(--color-primary);
    line-height: 1.2;
}

.ContentBlockUSPs__right .btn-dark-white {
    border: 0;
    background-color: var(--color-primary-dark);
}

.ContentBlockUSPs__ctaBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    display: none;
}

.ContentBlockUSPs__ctaBackground::before {
    content: "";
    position: absolute;
    left: -2.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 5rem;
    height: 5rem;
    background-color: var(--color-primary);
}

.ContentBlockUSPs__ctaBackground::after {
    content: "";
    position: absolute;
    right: -2.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 5rem;
    height: 5rem;
    background-color: var(--color-primary);
}

@media (max-width: 768px) {
    .ContentBlockUSPs .content {
        flex-direction: column !important;
    }
}

@media (min-width: 768px) {
    .ContentBlockUSPs .content {
        gap: 10rem !important;
    }

    .right__cta {
        color: var(--color-background);
    }

    .ContentBlockUSPs__right .btn-dark-white {
        color: var(--color-background);
        border: solid 2px var(--color-background);
        background-color: transparent;
    }

    .ContentBlockUSPs__ctaBackground {
        display: block;
    }
}
