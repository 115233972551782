.ZeitPreiseTabelle__Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50rem;
}

.ZeitPreiseTabelle__Container table {
    width: 100%;
}

.ZeitPreiseTabelle__Container table tr {
    border-top: 1px solid currentcolor;
    border-bottom: 1px solid currentcolor;
}

.ZeitPreiseTabelle__Container table td {
    padding: 2rem 0;
    text-align: left;
}

.ZeitPreiseTabelle__Container table td:first-of-type {
    padding-right: 5vw;
}

.ZeitPreiseTabelle__Container table td:last-of-type {
    padding-left: 5vw;
    text-align: right;
}

@media (min-width: 768px) {
    .ZeitPreiseTabelle__Container table td:first-of-type {
        padding-right: 0;
    }

    .ZeitPreiseTabelle__Container table td:last-of-type {
        padding-left: 0;
    }
}

.ZeitPreiseTabelle__Container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ZeitPreiseTabelle__Container ul li {
    display: flex;
    margin-bottom: 1rem;
    font-style: italic;
}

.ZeitPreiseTabelle__Container ul li::before {
    content: "\F26A";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
}

/* .table--ZeitPreiseTabelle {
    width: 100%;
    max-width: 50rem;
}

.table--ZeitPreiseTabelle tr {
    border-top: 1px solid currentcolor;
    border-bottom: 1px solid currentcolor;
}

.table--ZeitPreiseTabelle td {
    padding: 2rem 0;
    text-align: left;
}

.table--ZeitPreiseTabelle td:first-of-type {
    padding-right: 5vw;
}

.table--ZeitPreiseTabelle td:last-of-type {
    padding-left: 5vw;
    text-align: right;
}

@media (min-width: 768px) {
    .table--ZeitPreiseTabelle td:first-of-type {
        padding-right: 0;
    }

    .table--ZeitPreiseTabelle td:last-of-type {
        padding-left: 0;
    }
} */

/* .ul--usp {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ul--usp li {
    display: flex;
    margin-bottom: 1rem;
    font-style: italic;
}

.ul--usp li::before {
    content: "\F26A";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
} */
