@import url("./fonts.css");
@import url("https://use.typekit.net/dtx2awo.css");

:root {
    font-size: 14px;
    --color-background: rgba(255, 255, 255, 1);
    --color-background-dark: rgba(242, 242, 242, 1);
    --color-black: rgba(0, 0, 0, 1);
    --color-primary: rgba(53, 126, 98, 1);
    --color-primary-dark: rgba(9, 76, 68, 1);
    --color-secondary: rgba(200, 169, 107, 1);
    --content-width: 90%;
    --content-width-narrow: 90%;
    --header-height: 6rem; /* Fallback 6rem, wird durch JS angepasst */
}

@media (min-width: 576px) {
    :root {
        --content-width: 90%;
        --content-width-narrow: 90%;
    }
}

@media (min-width: 768px) {
    :root {
        --content-width: 85%;
        --content-width-narrow: 85%;
    }
}

@media (min-width: 992px) {
    :root {
        font-size: 16px;
        --content-width: 56rem;
        --content-width-narrow: 46rem;
    }
}

@media (min-width: 1200px) {
    :root {
        --content-width: 67rem;
        --content-width-narrow: 46rem;
    }
}

@media (min-width: 1400px) {
    :root {
        --content-width: 77rem;
        --content-width-narrow: 56rem;
    }
}

@media (min-width: 1600px) {
    :root {
        --content-width: 87rem;
        --content-width-narrow: 66rem;
    }
}

@media (min-width: 1920px) {
    :root {
        --content-width: 100rem;
        --content-width-narrow: 86rem;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--color-primary-dark);
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    transition: width 0.3s ease; /* für media query */
    /* outline: solid 1px magenta; */
    /* background: rgba(0, 255, 0, 0.1) !important; */
}

::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
}

body {
    overflow-x: hidden;
    background-color: var(--color-background);
}

#root > div,
article,
footer {
    scroll-behavior: smooth;
    scroll-margin: var(--header-height);
}

/* bootstrap override / extension */

.bi {
    line-height: 1;
}

.mb-6 {
    margin-bottom: 4.5rem !important;
}

.mb-7 {
    margin-bottom: 6rem !important;
}

.py-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.py-14 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
}

.h-100vh {
    min-height: 100vh;
}

.fs-medium-icon {
    font-size: 3rem;
}

.fs-large-icon {
    font-size: 5rem;
}

.fs-body {
    font-size: 1.2rem;
}

.cursor-pointer {
    cursor: pointer;
}

.alert {
    border: 0;
}

/* Allgemein */

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    width: var(--content-width);
    padding: 3rem 0;
}

.content--narrow {
    width: var(--content-width-narrow);
    padding: 3rem 0;
}

@media (min-width: 768px) {
    .content {
        padding: 4rem 0;
    }

    .content--narrow {
        padding: 4rem 0;
    }
}

/* font colors */

.color-background {
    color: var(--color-background) !important;
}

.color-background-dark {
    color: var(--color-background-dark) !important;
}

.color-black {
    color: var(--color-black) !important;
}

.color-primary {
    color: var(--color-primary) !important;
}

.color-primary-dark {
    color: var(--color-primary-dark) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-background:hover {
    color: var(--color-background) !important;
}

.color-background-dark:hover {
    color: var(--color-background-dark) !important;
}

.color-black:hover {
    color: var(--color-black) !important;
}

.color-primary:hover {
    color: var(--color-primary) !important;
}

.color-primary-dark:hover {
    color: var(--color-primary-dark) !important;
}

.color-secondary:hover {
    color: var(--color-secondary) !important;
}

/* Animationen */

@keyframes fade_in_scale {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade_in_top {
    0% {
        opacity: 0;
        transform: scale(0);
        transform: translateY(-1rem);
    }
    100% {
        opacity: 1;
        transform: scale(1);
        transform: translateY(0);
    }
}

/* Light, Dark Mode */

.light > * {
    transition-property: color, fill;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
    color: var(--color-background) !important;
    fill: var(--color-background) !important;
}

.dark > * {
    transition-property: color, fill;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
    color: var(--color-primary-dark) !important;
    fill: var(--color-primary-dark) !important;
}

/* text */

a {
    font-size: inherit;
    color: inherit;
}

a:hover {
    font-size: inherit;
    color: inherit;
}

p {
    margin-bottom: 0;
}

.h2 {
    display: block;
    position: relative;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: 400;
}

.h2--border::after {
    position: absolute;
    content: "";
    width: 30%;
    max-width: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    bottom: -1rem;
    border-bottom: 3px solid var(--color-background);
}

.h2--center {
    text-align: center;
}

.h4 {
    display: block;
    position: relative;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
}

.h4--border {
    margin-bottom: 3rem;
}

.h4--border::after {
    position: absolute;
    content: "";
    width: 30%;
    max-width: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    bottom: -2rem;
    border-bottom: 3px solid currentcolor;
}

.h4--spaceing {
    letter-spacing: 0.2rem;
}

.h4--center {
    text-align: center;
}

.h6 {
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
}

.h6--spaceing {
    letter-spacing: 0.1rem;
}

.h6--center {
    text-align: center;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.5;
}

/* content */

label {
    font-size: 1rem;
}

input {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid var(--color-primary-dark);
}

input.light {
    background-color: transparent;
    border: 0;
    color: var(--color-background);
    border-bottom: 2px solid var(--color-background);
}

input::placeholder {
    font-size: 1rem;
    color: var(--color-primary-dark);
}

input.light::placeholder {
    font-size: 1rem;
    color: var(--color-background);
    color: var(--color-background);
}

input:focus-visible {
    outline: 0;
}

textarea {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border: 2px solid var(--color-primary-dark);
    padding: 0.5rem;
}

textarea::placeholder {
    font-size: 1rem;
    color: var(--color-primary-dark);
}

textarea:focus-visible {
    outline: 0;
}

/* input[type="checkbox"] {
    font-size: 1.5rem;
    color: var(--color-primary-dark);
    background-color: transparent;
    border-radius: 0;
    border: 2px solid var(--color-primary-dark);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    width: 1rem;
    height: 1rem;
}

input[type="checkbox"]:focus-visible {
    outline: 0;
} */

textarea {
    resize: none;
}

.btn-dark-white {
    display: inline-block;
    font-size: 1rem;
    color: var(--color-background);
    border: solid 2px var(--color-background);
    padding: 0.8rem 3rem;
    background-color: transparent;
    text-decoration: none;
    transition: all 0.3s ease;
    max-width: 100%;
    white-space: break-spaces;
}

.btn-dark-white:hover {
    font-size: 1rem;
    color: var(--color-background);
    background-color: var(--color-primary-dark);
    border: solid 2px var(--color-primary-dark);
}

.btn-dark-primary {
    display: inline-block;
    font-size: 1rem;
    color: var(--color-background);
    border: 0;
    padding: 0.8rem 3rem;
    background-color: var(--color-primary-dark);
    text-decoration: none;
    transition: all 0.3s ease;
    max-width: 100%;
    white-space: break-spaces;
}

.btn-dark-primary:hover {
    font-size: 1rem;
    color: var(--color-background);
    background-color: var(--color-secondary);
}

.btn-dark-primary-border {
    display: inline-block;
    font-size: 1rem;
    color: var(--color-background);
    border: solid 2px var(--color-background);
    padding: 0.8rem 3rem;
    background-color: var(--color-primary-dark);
    text-decoration: none;
    transition: all 0.3s ease;
    max-width: 100%;
    white-space: break-spaces;
}

.btn-dark-primary-border:hover {
    font-size: 1rem;
    color: var(--color-background);
    border: solid 2px var(--color-secondary);
    background-color: var(--color-secondary);
}

.ul--usp {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ul--usp li {
    display: flex;
    margin-bottom: 1rem;
    font-style: italic;
}

.ul--usp li::before {
    content: "\F26A";
    font-family: "bootstrap-icons";
    font-size: 1.5rem;
    padding-right: 1rem;
    vertical-align: middle;
    font-style: normal;
    line-height: 1.5rem;
}

.ul--usp li:last-of-type {
    margin-bottom: 0;
}

.div--usp {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    margin: 1rem 0;
    background-color: var(--color-primary);
}

.div--usp i {
    font-size: 1.5rem;
    color: var(--color-secondary);
}

.div--usp span {
    color: var(--color-background);
}

.div--usp:last-of-type {
    margin-bottom: 0;
}

.contentSpecItem {
    height: 4rem;
}

@media (min-width: 768px) {
    .contentSpecItem {
        height: 6rem;
    }
}

.text-initial {
    text-transform: initial !important;
}

.fs-50px {
    font-size: 50px;
}

.fs-60px {
    font-size: clamp(50px, 5vw, 60px);
}

.font-rollerscript {
    font-family: "rollerscript-smooth", sans-serif;
    font-weight: 400;
    font-style: normal;
}
