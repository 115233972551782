.BlockControls {
    position: fixed;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    display: none;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 9;
}

.BlockControls a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    line-height: 1;
    text-decoration: none;
}

.BlockControls a span {
    font-size: 0.9rem;
    color: var(--color-background);
    display: none;
}

.BlockControls a i {
    font-size: 1rem;
    color: var(--color-background);
}

.BlockControls a:hover span {
    opacity: 1;
    display: inline;
    animation: fade_in_scale 0.2s;
}

@media (min-width: 768px) {
    .BlockControls {
        display: flex;
    }
}
