.Header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 1.5rem 7% 1.5rem 7%;
    user-select: none;
    pointer-events: visiblePainted;
    z-index: 10;
    transition: padding 0.3s ease;
}

.Header.dark.scroll .Header__bg {
    background-color: var(--color-background);
    bottom: 0;
}

.Header.light.scroll .Header__bg {
    background-color: var(--color-primary-dark);
    bottom: 0;
}

.Header__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease;
}

@media (min-width: 768px) {
    .Header {
        justify-content: space-between;
    }
    .Header.dropOpen {
        padding-bottom: 8.5rem;
    }
}

.Header__logo {
    position: absolute;
    top: 2.5rem;
    left: 7%;
}

.Header__logo .logo__svg {
    width: auto;
    height: 6rem;
    transition: all 0.3s ease;
}

.Header__logo .logo__svg.small {
    width: auto;
    height: 3.8rem;
    transform: translateY(-1.5rem);
}

@media (min-width: 768px) {
    .Header__logo .logo__svg {
        height: 8rem;
    }

    .Header__logo .logo__svg.small {
        height: 4rem;
        transform: translateY(-1.7rem);
    }
}

.Header__logo .logo__path2 {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.Header__logo .logo__path2.hidden {
    opacity: 0;
}

.Header__links {
    gap: 2rem;
    height: max-content;
    margin-left: auto;
    margin-right: 2rem;
    display: none;
}

@media (min-width: 768px) {
    .Header__links {
        display: flex;
    }
}

.Header__links a {
    color: var(--color-background);
    text-decoration: none;
}

.links__dropDown {
    position: relative;
    display: flex;
    flex-direction: column;
}

.dropDown__titel {
    cursor: pointer;
}

.dropDown__items {
    position: absolute;
    top: 2.5rem;
    display: flex;
    flex-direction: column;
    display: none;
    line-height: 2;
}

.dropDown__titel.open .dropDown__items {
    display: flex;
    opacity: 1;
    animation: fade_in_top 0.2s ease-out;
}

.dropDown__items a {
    transition: transform 0.3s ease;
}

.dropDown__items a:hover {
    transform: translateX(0.3rem);
}

.Header__menuBtn {
    cursor: pointer;
}

.Header__menuBtn i {
    font-size: 2.5rem;
}

.Header__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: scale(0);
}

.Header__menu a,
.Header__menu span {
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.1;
    transition: transform 0.3s ease;
    color: var(--color-background);
}

.Header__menu a:hover,
.Header__menu span:hover {
    transform: scale(1.025);
}

.menu__side {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    width: 90%;
    height: 100vh;
    padding: 4rem 4rem 7rem 4rem;
    background-color: var(--color-primary-dark);
    z-index: 2;
    transition: transform 0.3s ease;
}

.menu__side .SocialIcons {
    display: flex;
}

@media (min-width: 576px) {
    .Header__menu a,
    .Header__menu span {
        font-size: 2rem;
    }

    .menu__side {
        width: 70%;
        gap: 1.5rem;
    }
}

@media (min-width: 768px) {
    .menu__side {
        width: 50%;
        max-width: 50ch;
    }

    .menu__side .SocialIcons {
        display: none;
    }
}

.menu__side .dropdown__titel:hover {
    cursor: pointer;
}

.menu__side .dropdown__titel.open .dropdown__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    opacity: 1;
    animation: fade_in_top 0.3s ease-out;
}

.menu__side .dropdown__list {
    display: none;
    opacity: 0;
}

.side__close {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    width: 1.5rem;
    height: auto;
    cursor: pointer;
}

.menu__background {
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.6);
    z-index: 2;
}
