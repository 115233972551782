dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    padding: 5rem 2rem;
    width: 100%;
    z-index: 10;
}

dialog::backdrop {
    background-color: rgba(25, 25, 25, 0.6);
}

dialog > img {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: auto;
    cursor: pointer;
}

@media (min-width: 768px) {
    dialog {
        padding: 4rem 4rem;
        width: calc(50vw + 8rem);
    }
}
